@import '../../../../scss/theme-bootstrap';

.mpp-container--has-filters {
  .product-grid--filtered {
    .mix {
      display: none;
    }
  }
  .product-grid {
    min-height: auto;
    @include breakpoint($landscape-up) {
      &-wrapper {
        clear: both;
        display: inline;
      }
    }
  }
  .mpp-filter-set {
    display: inline-block;
    min-width: 45%;
    &__container {
      display: none;
      text-align: $ldirection;
      position: fixed;
      top: 140px;
      #{$rdirection}: 0;
      height: 100%;
      z-index: 9999;
      overflow: auto;
      -webkit-overflow-scrolling: touch;
      width: 100%;
      font-family: $medium-font-family;
      @include breakpoint($landscape-up) {
        display: block;
        text-align: inherit;
        position: inherit;
        top: inherit;
        #{$rdirection}: inherit;
        height: auto;
        z-index: 0;
        font-family: $font--text;
      }
      @include breakpoint($medium-up) {
        top: 169px;
        z-index: 1;
      }
    }
    &__reset,
    &__submit {
      width: 100%;
      float: none;
      border-radius: 0;
      border: 0;
      &:hover,
      &:active {
        background: inherit;
        color: inherit;
      }
      @include breakpoint($landscape-up) {
        float: $ldirection;
      }
    }
    &__section {
      &.active {
        position: fixed;
        background: $color-white;
        top: 143px;
        #{$ldirection}: 46px;
        width: 88%;
        z-index: 9;
        overflow: auto;
        max-height: 50vh;
        @include breakpoint($medium-up) {
          #{$ldirection}: 94px;
          top: 170px;
        }
        @include breakpoint($landscape-up) {
          position: inherit;
          background: none;
          top: inherit;
          #{$ldirection}: inherit;
          width: 34%;
          float: $ldirection;
        }
        @include breakpoint($large-up) {
          width: 23%;
        }
        .mpp-filter-set__title {
          font-size: 14px;
          text-transform: uppercase;
          padding-#{$ldirection}: 0;
          color: $color-black;
          width: 100%;
          float: none;
          @include breakpoint($landscape-up) {
            font-size: 12px;
            width: 88%;
            color: $color-gray;
          }
          .icon-caret--left {
            background-image: url('/media/export/cms/caret--left.svg');
            background-repeat: no-repeat;
            background-size: 18px 24px;
            width: 25px;
            height: 25px;
            float: $ldirection;
          }
          &__title {
            @include breakpoint($landscape-up) {
              @include icon('caret--up', after);
            }
          }
        }
        .animating {
          visibility: visible;
          max-height: none;
          padding: 0 30px;
          @include breakpoint($landscape-up) {
            padding: 10px 0;
            z-index: 1;
            background-color: $color-white;
          }
        }
      }
      .animating {
        visibility: hidden;
        max-height: 0;
      }
      width: 100%;
      text-transform: uppercase;
      position: relative;
      @include breakpoint($landscape-up) {
        width: 32%;
        position: inherit;
        float: $ldirection;
      }
      @include breakpoint($large-up) {
        width: 23%;
      }
      .mpp-filter-set__selections-tags {
        display: none;
      }
    }
    &__results {
      padding: 9px;
      width: 90%;
      background: $color-white;
      float: $rdirection;
    }
    @include breakpoint($medium-up) {
      float: $ldirection;
    }
    @include breakpoint($landscape-up) {
      width: 75%;
      padding-bottom: 40px;
      float: none;
    }
    @include breakpoint($large-up) {
      width: 82%;
    }
    &__body {
      float: $rdirection;
      width: 90%;
      padding: 0 15px;
      background: $color-white;
      padding-bottom: 60px;
      height: 415px;
      @include breakpoint($medium-up) {
        height: 600px;
      }
      @include breakpoint($landscape-up) {
        float: $ldirection;
        width: 99%;
        padding: 11px 0;
        height: auto;
      }
      @include breakpoint($large-up) {
        padding: 11px 35px;
      }
    }
    &__title {
      @include swap_direction(padding, 8px 0 7px 5px);
      span {
        float: $rdirection;
        @include breakpoint($landscape-up) {
          display: none;
        }
      }
      font-size: 15px;
      background: $color-white;
      border-bottom: 1px solid $color-lighter-gray;
      text-transform: capitalize;
      color: $color-gray;
      width: 100%;
      float: none;
      letter-spacing: 1px;
      @include breakpoint($landscape-up) {
        @include icon('caret--down', after);
        @include swap_direction(padding, 3px 0 0 20px);
        border: 1px solid $color-lighter-gray;
        width: 88%;
        font-size: 12px;
        font-weight: bold;
        color: $color-gray;
        position: relative;
        height: 30px;
        text-transform: uppercase;
        float: none;
        &::after {
          position: absolute;
          #{$rdirection}: 10px;
          top: 11px;
        }
      }
    }
    &__filters {
      @include breakpoint($landscape-up) {
        position: absolute;
        display: block;
        width: 72%;
      }
    }
    &__button {
      display: block;
      padding: 14px 0;
      text-transform: uppercase;
      font-size: 12px;
      color: $color-light-gray;
      line-height: 14px;
      letter-spacing: 0.035em;
      position: relative;
      cursor: pointer;
      border-bottom: 1px solid $color-lighter-gray;
      @include breakpoint($landscape-up) {
        @include icon('checkbox', before);
        border-bottom: none;
        padding: 0;
        width: 40%;
        display: inline-block;
        &.active {
          @include icon('close', before);
          &::before {
            border: 1px solid $color-light-gray;
            font-size: 12px;
            margin-#{$rdirection}: 9px;
            vertical-align: text-top;
          }
        }
        &::before {
          margin-#{$rdirection}: 9px;
          vertical-align: text-top;
        }
      }
      @include breakpoint($large-up) {
        width: 31%;
      }
      &.active {
        @include icon('caret--down', after);
        color: $color-black;
        font-weight: bold;
        &::after {
          float: $rdirection;
        }
        @include breakpoint($landscape-up) {
          color: $color-light-gray;
          font-weight: normal;
          &::after {
            display: none;
          }
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }
    &__mobile-cta {
      @include swap_direction(padding, 0 30px 0 15px);
      font-size: 13px;
      letter-spacing: 0.05em;
      text-decoration: none;
      text-align: center;
      min-width: 100%;
      position: relative;
      text-transform: uppercase;
      border: 1px solid $color-lighter-gray;
      color: $color-black;
      outline: 0;
      vertical-align: middle;
      display: inline-block;
      cursor: pointer;
      height: 40px;
      line-height: 37px;
      background: $color-white;
      transition: background 0.3s, color 0.3s, border-color 0.3s;
      font-weight: normal;
      .icon {
        position: absolute;
        top: 13px;
        #{$rdirection}: 12px;
        font-size: 11px;
      }
      @include breakpoint($medium-up) {
        float: $rdirection;
        min-width: 45%;
      }
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    &__footer {
      position: fixed;
      top: 500px;
      #{$ldirection}: 80px;
      width: 70%;
      z-index: 1;
      background: $color-white;
      border-top: 1px solid $color-white;
      border-bottom: 15px solid $color-white;
      display: inherit;
      @include breakpoint($medium-up) {
        #{$ldirection}: 23%;
        top: 600px;
      }
      @include breakpoint($landscape-up) {
        display: none;
      }
    }
    &__close {
      position: absolute;
      top: 0;
      #{$ldirection}: 0;
      z-index: 1;
      background: $color-lighter-gray;
      padding: 11px 0;
      width: 10%;
      text-align: center;
      @include breakpoint($landscape-up) {
        display: none;
      }
      .icon {
        &--close {
          &::before {
            font-weight: bold;
          }
        }
      }
    }
    &__mobile-text {
      font-weight: bold;
      padding: 10px;
      background: $color-white;
      width: 88%;
      text-transform: uppercase;
    }
    &__mobile-cta-clear {
      float: $rdirection;
      text-transform: capitalize;
      color: $color-light-grey-breadcrumb;
      text-decoration: underline;
      padding-#{$rdirection}: 18px;
    }
    &__selections-tags {
      position: absolute;
      top: 0;
      #{$rdirection}: 0;
      padding: 8px 20px 7px;
      text-overflow: ellipsis;
      width: 150px;
      white-space: nowrap;
      font-size: 14px;
      letter-spacing: 0.05em;
      border-bottom: 1px solid $color-gray;
      text-transform: capitalize;
      color: $color-light-gray;
      overflow: hidden;
      text-align: $rdirection;
    }
  }
}

.filter-show {
  .site-header {
    position: relative;
    z-index: 34;
    background: $color-white;
  }
}
