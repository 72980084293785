// This file is generated by `gulp icons`, do not modify

$icons: (
  bag: '\E001',
  caret--down: '\E002',
  caret--left: '\E003',
  caret--right: '\E004',
  caret--up: '\E005',
  checkbox--checked: '\E006',
  checkbox: '\E007',
  circle-caret--left: '\E008',
  circle-caret--right: '\E009',
  close: '\E00A',
  email: '\E00B',
  facebook: '\E00C',
  hamburger: '\E00D',
  head--outline: '\E00E',
  head--solid: '\E00F',
  heart--filled: '\E010',
  heart--outline: '\E011',
  instagram: '\E012',
  livechat: '\E013',
  location--filled: '\E014',
  location: '\E015',
  logo: '\E016',
  map-marker: '\E017',
  minus: '\E018',
  pinterest--circle: '\E019',
  pinterest: '\E01A',
  plus: '\E01B',
  radio--checked: '\E01C',
  radio: '\E01D',
  stars: '\E01F',
  twitter: '\E020',
  youtube--play: '\E021',
  youtube: '\E022'
);
